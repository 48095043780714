.app {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.sidebar{
  background-color: #282c34;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 50px 0px;
  /* transition: .01s; */
}

.sidebar:hover{
  width: 200px;
}

.sidebar:hover .navLinks{
  width: 80%;
  justify-content: flex-start;
  padding: 0px 10px;
}




.navLinks{
  height: 30px;
  width: 30px;
  background-color: transparent;
  border-radius: 5px;
  margin: 0px auto;
  cursor: pointer;
  transition: .0s;
  border: none;
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  overflow: hidden;
}
.navLinks:hover{
  background-color: #ffffff20;
}

.navLinks.current{
  background-color: #0090ff90;
}

.navLinks p{
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 0px;
  transition: .5s;
  display: none;
}

.sidebar:hover .createNav{
  height: 80px;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ffffff50;
}

.sidebar:hover p{
  display: block;
  font-size: 14px;
}

.content{
  padding-top: 10px;
  flex: 1;
  padding-left: 50px;
}

hr{
  width: 70%;
  border-color: #55555550;
}

.navOptions{
  background-color: #282c34;
  position: absolute;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #55555550;
  border-radius: 5px;
  gap: 10px;
  min-width: 100px;
}

.navOptions a{
  padding: 5px;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.navOptions a:hover{
  background-color: #ffffff20;
  transition: .2s;
}

button:disabled{
  opacity: 0.5;
  cursor: default !important;
}

.dataList span{
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
}

.dataList span:hover{
  background-color: #0090ff90;
}

::-webkit-scrollbar{
  display: none;
}